<template>
  <div>
    <a-modal v-model="modalVisible" title="新增" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm('1')">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
          <h3 style="font-weight: bold; color: #333;text-align: center;font-size: 25px">试点电梯清单</h3>
          <a-form-model-item label="电梯使用登记证号" prop="registernum">
            <a-input v-model.trim="formDatas.registernum" />
          </a-form-model-item>
          <a-form-model-item label="下次检验日期" prop="nexttime">
            <a-date-picker v-model="formDatas.nexttime"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="是否纳入96333平台" prop="is96333">
            <a-radio-group v-model="formDatas.is96333">
              <a-radio :value="0">是</a-radio>
              <a-radio :value="1">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="是否购买电梯保险" prop="insurancebuy">
            <a-radio-group v-model="formDatas.insurancebuy">
              <a-radio :value="0">是</a-radio>
              <a-radio :value="1">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remarks">
            <a-textarea v-model.trim="formDatas.remarks" />
          </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '300px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        registernum:'',
        nexttime:'',
        is96333:'',
        insurancebuy:'',
        remarks:'',
      },
      //这里面的数据属于必填项
      formRules: {
      },
      userloginurlList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas(){

    },
    confirm(checkstatus) {
    },
  }
}
</script>